@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;1,100;1,200;1,700&display=swap");
/* font-family: 'Poppins', sans-serif; */

body {
  overflow-x: hidden;
}
.border-primary {
  border-color: transparent !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.eael-creative-button {
  right: 0 !important;
}
