.tabs-section {
    background: linear-gradient(-45deg, rgb(64, 93, 230), rgb(88, 81, 219), rgb(131, 58, 180), rgb(193, 53, 132), rgb(225, 48, 108), rgb(253, 29, 29), rgb(245, 96, 64), rgb(247, 119, 55), rgb(252, 175, 69), rgb(255, 205, 75), rgb(81, 255, 0), rgb(67, 210, 0));
    background-size: 400% 400%!important;
    -webkit-animation: Gradient 15s ease-in-out infinite;
    animation: Gradient 15s ease-in-out infinite;
    text-align: center;
    width: 100%;
    height: 350px;
    position: relative;
    padding: 50px 0;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.tabs-wrapper .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    box-shadow: 0px 0px 10px 0px rgba(13.000000000000039,102.00000000000034,255,.66);
    border: none;

}


.tabs-wrapper  .nav-tabs .nav-link:focus, .tabs-wrapper  .nav-tabs .nav-link:hover {
    border-color:transparent;
    isolation: isolate;
}

.tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}




.svg-section {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
}
.svg-section svg {
    width: calc(150% + 1.3px);
    height: 25px;
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.svg-section svg .shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
}

.tabs-btns-wrapper {
    background-color: #FFFFFF59;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:340px;
    margin: auto;
    border-bottom:none !important;
}





.tab-btn {
    display: block !important;
    text-align: center !important;
    padding: 10px 15px !important;
    color: #fff !important;
    font-weight: 800 !important;
    border-radius: 50px !important;
    overflow: hidden !important;
    position: relative !important;
}
.tab-btn.active::before {
    position: absolute;
    content: '';
    background-color: transparent;
    background-image: linear-gradient(-45deg,#27a2ff 0%,#4062ff 100%);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    transition: all .3s ease-out;
}
.tab-btn i {
    margin-right: 10px;
}
.tab-btn-content {
    position: relative;
}

.tabs-wrapper h6 {
    color: #FFFFFF91;
    font-size: 15px;
    font-weight: 300;
    margin: 10px 0 20px !important;
}

.tabs-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}


.tab-content-btn {
    color: #fff !important;
    background-color: #FFFFFF8F;
    border-radius: 30px;
    min-width: 150px;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    cursor:pointer;
}


.tab-content-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 100%;
    margin: -15px 0 0 1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    transform: scale3d(1,2,1);
    transition: opacity .3s,-webkit-transform .3s;
    transition: transform .3s,opacity .3s;
    transition-timing-function: cubic-bezier(.7,0,.9,1);
}


.tab-content-btn:hover:before {
    -webkit-transform: scale3d(40,40,1);
    transform: scale3d(40,40,1);
    background-color: #fff;
  }


  .tab-content-btn:hover .tabs-content-inner{
    color: #0ea1ff !important;
    position: relative;
    z-index: 2;
  }



  /* FAQ CSS Starts Here */

  .faq_main_part{
    background: #f9f8f8;
    padding: 60px 60px 70px;
  }


  .faq_main_part .title .text-part {
    font-size: 35px;
    color: #111111;
    line-height: 46px;
    font-weight: 700;
    margin-bottom: 40px;
}

.faq_main_part .accordion-item{
    margin: 10px;
}

.faq_main_part .accordion-button {
   font-weight: 600;
}


.faq_main_part .accordion-button:not(.collapsed) {
    color: #333;
    /* background-color: #f77737; */
    background: linear-gradient(-45deg, rgb(64, 93, 230), rgb(88, 81, 219), rgb(131, 58, 180), rgb(193, 53, 132), rgb(225, 48, 108), rgb(253, 29, 29), rgb(245, 96, 64), rgb(247, 119, 55), rgb(252, 175, 69), rgb(255, 205, 75), rgb(197, 255, 56), rgb(81, 255, 0));
    width: 100%;
    height:max-content;
     background-size: 400% 400%!important;
     -webkit-animation: Gradient 15s ease-in-out infinite;
     animation: Gradient 15s ease-in-out infinite;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.faq_main_part .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/accordionIcon.svg") !important;
}


.faq_main_part  .accordion-button:not(.collapsed) {
    color: #fff !important;
}


.faq_main_part .accordion-body p {
    background: #ffffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #505050;
    padding: 15px 14px 15px !important;
    width: 100%;
}

.img_part{
        background: url("../../assets/images/video1.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
}

.media-icon {
    position: relative;
    display: inline-block;
    z-index: 1;
}


.media-icon .popup-videos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff5421;
    background: #ffffff;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    text-align: center;
}


.media-icon .popup-videos:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
    transition: all 0.3s ease;
    z-index: -1;
}


.media-icon .popup-videos i {
    margin-left: 7px;
    font-size: 30px;
    padding-top: 20px;
}

.media-icon.orange-color .popup-videos:hover:before {
    background: #ffffff;
}


@keyframes pulse-border {
    100%{
        transform: scale3d(1);
        opacity: 0;
    }
  }


  /* FAQ CSS Ends Here */



/* NewsLetter CSS Starts Here */

#section-cta {
	background: url(http://templates.thememodern.com/compact/images/bg/bg-team.jpg)
		top fixed;
	padding: 60px 0 60px 0;

}

#section-cta section {
	background: #fff;
}

section {
	-webkit-background-size: 100% !important;
	-moz-background-size: 100% !important;
	-o-background-size: 100% !important;
	background-size: 100% !important;
	position: relative;
}

.sep-background-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #29b6f6;
	opacity: 0.9;
	z-index: 1;
}


section > .container {
	position: relative;
	z-index: 2;
}


.quick_newsletter .newsletter-info {
	color: #f8f8f8;
}


.quick_newsletter .newsletter-info {
	color: #f8f8f8;
}


.quick_newsletter .newsletter-info h3 {
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 36px;
	margin: 0px;
	color: #f8f8f8;
}


.quick_newsletter .newsletter-element .input-text {
	background-color: #f8f8f8;
	padding: 15px 20px;
	display: inline-block;
	border: none;
	outline: none;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}


.quick_newsletter .newsletter-element .newsletter-submit {
	font-size: 15px;
	font-weight: bold;
	color: #ffffff;
	line-height: 21px;
	padding: 15px 30px 15px 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	min-width: auto;
	position: relative;
	display: inline-block;
	background-color: #555555;
}


.quick_newsletter .newsletter-element .newsletter-submit:hover {
	background-color: #666565;
}


.quick_newsletter .newsletter-element .newsletter-submit i {
	margin-right: 5px;
}


.quick_newsletter p {
	margin-bottom: 0px;
}


.newsletter-element form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



@media only screen and (max-width: 767px) {
	.newsletter-element .input-text,
	.newsletter-element .newsletter-submit {
		margin-top: 20px;
	}
}


/* Newsletter CSS Ends Here */



/* Responsive Css Starts Here */

@media only screen and (max-width:767px){

    .nios_info a h3 {
        font-size: 40px;
    }


    .banner-section video {
        width: 100%;
    }
    
    .banner-content-wrapper h1,.ahsec_title h1,.headingblack h1,.choose_title h3,.tabs_inner-content h2{
        font-size: 35px;
    }

   
    .tabs-section{
        height: 100%;
    }
    .tab-content-btn{
        padding: 10px 20px;
    }
    .faq_main_part .title .text-part,.headingblack h2,.tabs_inner-content h5{
        font-size: 25px;
    }

    .faq_main_part{
        padding: 25px;
    }

    .newsletter-element form {
        display: flex;
        flex-direction: column;
    }

    .footer-wrapper,.ahsec_title p{
        padding: 15PX;
    }

    .footer-bottom-section p{
        text-align: center;
        font-size: 12px !important;
    }

    .footer-social-links {
        justify-content: center;
        padding-top: 10px;
    }

    .tabs-btns-wrapper{
        width: 100%;
    }

    .modal_show{
        width: 300px !important;
        padding: 1rem !important;
    }

    .modalContainer{
        z-index: 9 !important;
    }
    section {
        padding: 0;
        background: #fff;
    }

    .typewriter_effect{
        font-size: 12px;
    }

    .previous_one .accordion h1 button,.college_intro .intro_inner p, .college_intro .intro_inner ul{
        font-size: 16px;
    }


    .previous_pdf .nav-pills .nav-link,.previous_pdf .accordion h1 button{
        padding: 16px;
        font-size: 14px;
    }

    .icia_button h2{
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px !important;
    }

    .icia_button .button_div .btn{
        padding: 15px;
        min-width: 100%;
    }

    .icia_button h3{
        text-align: center;
    }

    .button_div .btn {
        padding: 15px;
        font-size: 13px;
        width: 98%;
        height: 50px !important;
        min-height: none !important;
    }

    .feedback1 h2, .feedback_number span a{
        font-size: 25px;
    }

    .feedback_icons ul{
        justify-content: center;
    }

    .feedback_icons ul li{
        padding: 5px;
    }


    .footer-social-links{
        justify-content: center !important;
    }



    .gauhati_info a h5,.guahati_content h3, .nios_info a h5,.gauhati_info a h3, .guahati_content h2{
        font-size: 25px;
    }

    .first_year_acc .main-heading {
        font-size: 20px;
    }


    .advanced-heading-content {
        font-size: 55px;
    }
    



}



/* Responsive CSS Ends Here */

 .input-text {
    background-color: #f8f8f8;
    border: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    outline: none;
    padding: 15px 20px;
    width: 100%;
}