.downloadButton{
    transform: rotate(90deg);
    background: #c9e9ec;
   
    position:fixed;
    right: -2.6rem;
    top:16rem;
    width: 8rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popUp{
    background-color:#EFFEFF;
  
    position: fixed;
    top: 11.5rem;
    width: 18rem;
    height: 12rem;
    border-radius: 8px;
    z-index: 9999;
    padding: 0.5rem;
    transition: all 0.3s ease-out;
}
.move{
transform: translateX(0);
}
.unMove{
transform: translateX(200%);
}
.closeButton{
position: absolute;
right: 0.3rem;
top: 0;
cursor: pointer;
}

.downloadButton{
    transform: rotate(90deg);
    background: #46ca82;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    position:fixed;
    right: -2.6rem;
    top:16rem;
    width: 8rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    font-family: 'Prompt';
    align-items: center;
}
.popUp{
    background-color:#e7eceb;
    box-shadow: rgba(0,0,0,0.35) 0 6px 100px 0;
    position: fixed;
    top: 11.5rem;
    width: 18rem;
    height: 12rem;
    border-radius: 8px;
    z-index: 9999;
    padding: 0.5rem;
    transition: all 0.3s ease-out;
}
.move{
transform: translateX(0);

}
.move1{
  transform: translateX(-80%);
}
.unMove{
transform: translateX(200%);
}
.closeButton{
position: absolute;
right: 0.3rem;
top: 0;
cursor: pointer;
}
/*-----------button  dowmnload css===========*/


*, *::after, *::before {
    box-sizing: border-box;
  }
  
 
  .bg{
    background: #333;
  }
  .button1{
    width: 250px;
    height: 70px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 22px;
    border: 3px solid #28944c;
    color: #28944c;
    line-height: 64px;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: 0 0 5px #28944c;
    border-radius: 70px;
    animation: over 6s infinite;
    outline: none;
  }
  
  .button1:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background: #28F2B3;
    animation: load 6s infinite;
  }
  
  .button1:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-bottom: 3px solid;
    border-right: 3px solid;
    transform: rotate(45deg);
    box-shadow: 3px 3px #28944c, 6px 6px #28F2B3;
    
    right: 45px;
    top: 18px;
    animation: dwd 6s infinite;
  }
  
  @keyframes over {
    0%, 20% {
      line-height: 64px;
      width: 250px;
    }
    25%, 100% {
      line-height: 150px;
      border-color: #28F2B3;
    }
    30%, 100% {
      width: 70px;
      border-color: #444;
    }
  }
  
  @keyframes load {
    0%, 40% {
      height: 0;
    }
    90%, 100% {
      height: 100%;
    }
  }
  
  @keyframes dwd {
    0%, 20% {
      right: 45px;
      top: 18px;
    }
    30%, 100% {
      right: 25px;
    }
    30%, 40%, 50%, 60%, 70%, 80% {
      top: 14px;
    }
    35%, 45%, 55%, 65%, 75%, 85% {
      top: 22px;
    }
    54% {
      color: #28F2B3;
      box-shadow: 3px 3px #333, 6px 6px #28F2B3;
    }
    55%, 85% {
      color: #333;
      box-shadow: 3px 3px #28F2B3, 6px 6px #333;
      border-width: 3px;
      height: 15px;
    }
    90%, 100% {
      box-shadow: 1px 1px #333;
      border-width: 5px;
      height: 25px;
      color: #fff;
    }
  }