.bannerAds {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  align-items: center;
}
.downloadButton {
  transform: rotate(90deg);
  background: #c9e9ec;

  position: fixed;
  right: -2.6rem;
  top: 16rem;
  width: 8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popUp {
  background-color: #effeff;

  position: fixed;
  top: 11.5rem;
  width: 18rem;
  height: 12rem;
  border-radius: 8px;
  z-index: 9999;
  padding: 0.5rem;
  transition: all 0.3s ease-out;
}
.move {
  transform: translateX(-70%);
}
.unMove {
  transform: translateX(500%);
}
.closeButton {
  position: absolute;
  right: 0.15rem;
  top: 0;
  cursor: pointer;
}
